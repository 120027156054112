export const LOGOUT = "api/auth/logout";
export const LOGIN = "api/auth/login";
export const PROFILE = "api/auth/profile";
export const MEMBERS = "api/users";
export const SERVICES = "api/services";
export const ADMINS = "api/admins";
export const MANAGE_ADMIN = "api/admins/{admin_id}";
export const LEADS = "api/leads";
export const PAYMENT_METHOD = "api/options/payment-methods";
export const MEMBER_CHANGE_PASSWORD = "api/user";
export const ADMIN_CHANGE_PASSWORD = "api/admin";
export const POSTING = "api/posts";
export const NOTIFICATIONS = "api/notifications";
export const DASHBOARD = "api/stats/dashboard";
export const MEMBERSLIST = "api/options/users";
export const FINANCIALMEMBERS = "api/payments";
export const DUE_PAYMENTS = "api/due-payments";
export const TRANSACTIONHISTORY = "api/users/id/histories";
export const CLEARDUEPAYMENT = "api/users/id/due-payment";
export const GET_ALL_USER = "api/janus-users";
export const GET_CHAT_USER = "api/chat-users";
export const ROOMS = "api/rooms";
export const NEWS = "api/news";
export const NEWS_UPLOAD_MEDIA = "api/news/upload-media";
export const PAYMENT_HISTORY = "api/payment-history";
export const PAYMENT_SUBSCRIPTION = "api/payment-subscription";
export const PAYMENT_RECEIPT_URL = "api/payment-receipt-url";
