import Vue from "vue";
import Vuex from "vuex";

import { user } from "./modules/user";
import { members } from "./modules/member";
import { coreBuying } from "./modules/coreBuying";
import { admins } from "./modules/admin";
import { associates } from "./modules/associate";
import { paymenthistory } from "./modules/paymenthistory";
import { common } from "./modules/common";
import { leads } from "./modules/lead";
import { paymentMethod } from "./modules/paymentMethod";
import { posting } from "./modules/posting";
import { notification } from "./modules/notification";
import { dashboard } from "./modules/dashboard";
import { financialDashboard } from "./modules/financialDashboard";
import { duePayment } from "./modules/duePayment";
import { news } from "./modules/news";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
    drawer: null,
  },
  getters: {
    getLoaderStatus(state) {
      return state.loading;
    },
    drawer(state) {
      return state.drawer;
    },
  },
  mutations: {
    setLoader(state, status) {
      state.loading = status;
    },
    setDrawer(state, status) {
      state.drawer = status;
    },
  },
  actions: {},
  modules: {
    user,
    members,
    coreBuying,
    admins,
    associates,
    paymenthistory,
    common,
    leads,
    paymentMethod,
    posting,
    notification,
    dashboard,
    financialDashboard,
    duePayment,
    news,
  },
});
